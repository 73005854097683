import { Component } from "react";

export class Input extends Component {
  render() {
    return (
      <div class="tr-relative tr-w-full tr-min-w-[10rem] tr-max-w-none tr-border-gray-300 tr-mt-0 tr-rounded-md tr-border tr-shadow-sm">
        <input
          class="tr-w-full focus:tr-ring-2 focus:tr-outline-0 tr-bg-white hover:tr-bg-gray-50 focus:tr-ring-blue-300 tr-text-gray-700 tr-pl-4 tr-pt-2 tr-pb-2 tr-text-sm tr-font-medium tr-rounded-md tr-border-0 placeholder:tr-text-gray-500 tr-pr-10"
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}
