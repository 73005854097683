const pastas = [
  {
    name: "Doce de leite",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 100,
  },
  {
    name: "Geleia/Doce cremoso de fruta light",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 150,
  },
  {
    name: "Geleia",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 110,
  },
  {
    name: "Geleia 100% fruta",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 200,
  },
  {
    name: "Homus (grão de bico e sal)",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 110,
  },
  {
    name: "Leite condensado",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 100,
  },
  {
    name: "Pasta de amendoim",
    group: "Pastas, Doces e Acompanhamentos",
    gramsInBaseValue: 50,
  },
];

const oleaginosas = [
  { name: "Amendoim torrado seco", group: "Oleaginosas", gramsInBaseValue: 15 },
  { name: "Avelã", group: "Oleaginosas", gramsInBaseValue: 14 },
  { name: "Castanha de caju", group: "Oleaginosas", gramsInBaseValue: 16 },
  { name: "Castanha do Pará", group: "Oleaginosas", gramsInBaseValue: 12 },
  { name: "Coco", group: "Oleaginosas", gramsInBaseValue: 25 },
  { name: "Nozes Pecan", group: "Oleaginosas", gramsInBaseValue: 12 },
  { name: "Pasta de amendoim", group: "Oleaginosas", gramsInBaseValue: 15 },
];

const leguminosas = [
  {
    name: "Canjica cozida ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 100,
  },

  {
    name: "Cevadinha cozida (grão de trigo) ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 90,
  },

  {
    name: "Ervilha cozida ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 140,
  },

  {
    name: "Feijão carioca cozido ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 140,
  },

  {
    name: "Feijão preto cozido ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 140,
  },

  {
    name: "Grão de bico cozido ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 65,
  },

  {
    name: "Lentilha cozida ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 100,
  },

  {
    name: "Milho cozido ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 100,
  },

  {
    name: "Soja cozida ",
    group: "Leguminosas e Cereais",
    gramsInBaseValue: 65,
  },
];

const frutas = [
  { name: "Abacate", group: "Frutas", gramsInBaseValue: 40 },
  { name: "Abacaxi", group: "Frutas", gramsInBaseValue: 150 },
  { name: "Acerola", group: "Frutas", gramsInBaseValue: 216 },
  { name: "Ameixa vermelha", group: "Frutas", gramsInBaseValue: 150 },
  { name: "Banana", group: "Frutas", gramsInBaseValue: 75 },
  { name: "Caqui", group: "Frutas", gramsInBaseValue: 110 },
  { name: "Carambola", group: "Frutas", gramsInBaseValue: 225 },
  { name: "Cereja", group: "Frutas", gramsInBaseValue: 100 },
  { name: "Fruta do conde", group: "Frutas", gramsInBaseValue: 60 },
  { name: "Goiaba", group: "Frutas", gramsInBaseValue: 140 },
  { name: "Jabuticaba", group: "Frutas", gramsInBaseValue: 140 },
  { name: "Jaca", group: "Frutas", gramsInBaseValue: 72 },
  { name: "Kiwi", group: "Frutas", gramsInBaseValue: 114 },
  { name: "Laranja", group: "Frutas", gramsInBaseValue: 160 },
  { name: "Limão (suco puro)", group: "Frutas", gramsInBaseValue: 280 },
  { name: "Maçã", group: "Frutas", gramsInBaseValue: 120 },
  { name: "Mamão formosa/papaya", group: "Frutas", gramsInBaseValue: 200 },
  { name: "Manga", group: "Frutas", gramsInBaseValue: 110 },
  { name: "Maracujá (suco puro)", group: "Frutas", gramsInBaseValue: 70 },
  { name: "Melão", group: "Frutas", gramsInBaseValue: 270 },
  { name: "Morango", group: "Frutas", gramsInBaseValue: 240 },
  { name: "Nectarina", group: "Frutas", gramsInBaseValue: 160 },
  { name: "Pêra", group: "Frutas", gramsInBaseValue: 120 },
  { name: "Pêssego", group: "Frutas", gramsInBaseValue: 180 },
  { name: "Uva Itália", group: "Frutas", gramsInBaseValue: 96 },
  { name: "Ameixa preta seca", group: "Frutas", gramsInBaseValue: 30 },
  { name: "Damasco seco", group: "Frutas", gramsInBaseValue: 28 },
  { name: "Uva passa", group: "Frutas", gramsInBaseValue: 24 },
  { name: "Tâmara seca", group: "Frutas", gramsInBaseValue: 24 },
];

const laticinios = [
  {
    name: "Iogurte natural desnatado",
    group: "Laticínios",
    gramsInBaseValue: 170,
  },
  {
    name: "Iogurte natural integral",
    group: "Laticínios",
    gramsInBaseValue: 110,
  },
  {
    name: "Leite longa vida desnatado",
    group: "Laticínios",
    gramsInBaseValue: 200,
  },
  { name: "Leite em pó desnatado", group: "Laticínios", gramsInBaseValue: 20 },
  {
    name: "Leite longa vida integral",
    group: "Laticínios",
    gramsInBaseValue: 110,
  },
  { name: "Leite em pó integral", group: "Laticínios", gramsInBaseValue: 14 },
  {
    name: "Leite longa vida semidesnatado",
    group: "Laticínios",
    gramsInBaseValue: 165,
  },
  { name: "Nata", group: "Laticínios", gramsInBaseValue: 30 },
  { name: "Queijo cottage", group: "Laticínios", gramsInBaseValue: 75 },
  {
    name: "Queijo cremoso (cream cheese)",
    group: "Laticínios",
    gramsInBaseValue: 30,
  },
  { name: "Queijo gorgonzola", group: "Laticínios", gramsInBaseValue: 20 },
  { name: "Queijo minas", group: "Laticínios", gramsInBaseValue: 30 },
  { name: "Queijo mussarela", group: "Laticínios", gramsInBaseValue: 20 },
  { name: "Queijo parmesão", group: "Laticínios", gramsInBaseValue: 18 },
  { name: "Queijo prato", group: "Laticínios", gramsInBaseValue: 20 },
  { name: "Queijo provolone", group: "Laticínios", gramsInBaseValue: 20 },
  { name: "Requeijão light", group: "Laticínios", gramsInBaseValue: 40 },
  { name: "Requeijão tradicional", group: "Laticínios", gramsInBaseValue: 23 },
  { name: "Ricota", group: "Laticínios", gramsInBaseValue: 40 },
];

const carboidratos = [
  {
    name: "Abóbora cabotiá cozida",
    group: "Carboidratos",
    gramsInBaseValue: 260,
  },
  { name: "Arroz branco cozido", group: "Carboidratos", gramsInBaseValue: 100 },
  {
    name: "Arroz integral cozido",
    group: "Carboidratos",
    gramsInBaseValue: 100,
  },
  {
    name: "Batata doce assada (com casca)",
    group: "Carboidratos",
    gramsInBaseValue: 140,
  },
  { name: "Batata doce cozida", group: "Carboidratos", gramsInBaseValue: 40 },
  {
    name: "Batata inglesa cozida",
    group: "Carboidratos",
    gramsInBaseValue: 250,
  },
  { name: "Farinha de mandioca", group: "Carboidratos", gramsInBaseValue: 32 },
  { name: "Goma de tapioca", group: "Carboidratos", gramsInBaseValue: 40 },
  { name: "Macarrão cozido", group: "Carboidratos", gramsInBaseValue: 90 },
  { name: "Mandioca cozida", group: "Carboidratos", gramsInBaseValue: 100 },
  { name: "Mandioquinha cozida", group: "Carboidratos", gramsInBaseValue: 160 },
  { name: "Pão de milho", group: "Carboidratos", gramsInBaseValue: 39 },
  { name: "Pão de centeio", group: "Carboidratos", gramsInBaseValue: 50 },
  {
    name: "Pão de forma tradicional fatiado",
    group: "Carboidratos",
    gramsInBaseValue: 50,
  },
  { name: "Pão de forma caseiro", group: "Carboidratos", gramsInBaseValue: 30 },
  {
    name: "Pão de queijo coquetel",
    group: "Carboidratos",
    gramsInBaseValue: 42,
  },
  { name: "Pão francês", group: "Carboidratos", gramsInBaseValue: 45 },
  { name: "Pão integral", group: "Carboidratos", gramsInBaseValue: 50 },
  { name: "Pão 7 grãos/12 grãos", group: "Carboidratos", gramsInBaseValue: 50 },
  { name: "Polenta", group: "Carboidratos", gramsInBaseValue: 175 },
  { name: "Purê de batatas", group: "Carboidratos", gramsInBaseValue: 120 },
];

const proteinas = [
  { name: "Acém grelhado/refogado", group: "Proteinas", gramsInBaseValue: 85 },
  {
    name: "Alcatra grelhada/refogada",
    group: "Proteinas",
    gramsInBaseValue: 85,
  },
  {
    name: "Atum (conserva em água)",
    group: "Proteinas",
    gramsInBaseValue: 160,
  },
  {
    name: "Atum (conserva em óleo)",
    group: "Proteinas",
    gramsInBaseValue: 120,
  },
  { name: "Camarão cozido", group: "Proteinas", gramsInBaseValue: 180 },
  { name: "Carne moída refogada", group: "Proteinas", gramsInBaseValue: 60 },
  {
    name: "Cação em posta grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 160,
  },
  {
    name: "Coxa de frango sem pele assada",
    group: "Proteinas",
    gramsInBaseValue: 90,
  },
  {
    name: "Sobrecoxa de frango sem pele assada",
    group: "Proteinas",
    gramsInBaseValue: 80,
  },
  {
    name: "Coxão mole/duro grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 85,
  },
  { name: "Fígado bovino refogado", group: "Proteinas", gramsInBaseValue: 85 },
  {
    name: "Filé de frango grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 100,
  },
  {
    name: "Filé de tilápia grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 160,
  },
  {
    name: "Filé mignon grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 80,
  },
  { name: "Lombo de porco assado", group: "Proteinas", gramsInBaseValue: 100 },
  {
    name: "Maminha grelhada/refogada",
    group: "Proteinas",
    gramsInBaseValue: 120,
  },
  {
    name: "Patinho grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 85,
  },
  {
    name: "Peito de frango grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 100,
  },
  {
    name: "Salmão grelhado/refogado",
    group: "Proteinas",
    gramsInBaseValue: 100,
  },
  { name: "Sardinha em conserva", group: "Proteinas", gramsInBaseValue: 90 },
  { name: "Whey Protein", group: "Proteinas", gramsInBaseValue: 40 },
];

export const foods = [
  ...pastas,
  ...oleaginosas,
  ...leguminosas,
  ...frutas,
  ...laticinios,
  ...carboidratos,
  ...proteinas,
];
