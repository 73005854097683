import React, { useState } from "react";
import {
  Card,
  SelectBox,
  SelectBoxItem,
  Title,
  List,
  ListItem,
  Flex,
} from "@tremor/react";
import { Input } from "./Input";
import logo from "./assets/logo.png";
import { foods } from "./data/foods";
import "@tremor/react/dist/esm/tremor.css";
import "./App.css";

function App() {
  // TODO: Deploy using vercel
  const [search, setSearch] = useState("");
  const [valueInGrams, setValueInGrams] = useState(0);
  const [group, setGroup] = useState("");
  const [selectedFoodIndex, setSelectedFoodIndex] = useState(null);
  const [equilivalentFoods, setEquilivalentFoods] = useState([]);
  const [totalEquilivalentFoods, setTotalEquilivalentFoods] = useState([]);

  const foodNames = foods.map((food) => food.name);
  const foodsbyGroup = foods.reduce((groups, food) => {
    return {
      ...groups,
      [food.group]: [...(groups[food.group] || []), food],
    };
  }, {});

  const handleSearch = (e) => {
    const searchText = e.target.value;
    console.log(searchText);
    setSearch(searchText);

    if (searchText) {
      const filteredFoods = totalEquilivalentFoods.filter((food) =>
        food.name.toLowerCase().includes(searchText.toLowerCase())
      );
      console.log({ filteredFoods, totalEquilivalentFoods });
      setEquilivalentFoods(filteredFoods);
    } else {
      setEquilivalentFoods(totalEquilivalentFoods);
    }
  };

  const handleSelect = (value) => {
    const foodsIndex = foodNames.indexOf(value);

    if (foodsIndex !== undefined) {
      setSelectedFoodIndex(foodsIndex);
      setGroup(foods[foodsIndex].group);
      calculateEquilivalentFoods(foodsIndex, valueInGrams);
    } else {
      setSelectedFoodIndex(null);
      setGroup("");
    }
  };

  const calculateEquilivalentFoods = (foodIndex, newGrams) => {
    const selectedFood = foods[foodIndex];
    if (selectedFood && newGrams) {
      const ratio = newGrams / selectedFood.gramsInBaseValue;

      const newEquilivalentFoods = foodsbyGroup[selectedFood.group].reduce(
        (ef, food) => {
          if (food.name === selectedFood.name) {
            return ef;
          }
          return [
            ...ef,
            {
              name: food.name,
              grams: `${Math.ceil(food.gramsInBaseValue * ratio)}g`,
            },
          ];
        },
        []
      );

      setEquilivalentFoods(newEquilivalentFoods);
      setTotalEquilivalentFoods(newEquilivalentFoods);
    } else {
      setEquilivalentFoods([]);
      setTotalEquilivalentFoods([]);
    }
  };

  const handleValueInGramsChange = (event) => {
    const newGrams = event.target.value;
    setValueInGrams(newGrams);
    calculateEquilivalentFoods(selectedFoodIndex, newGrams);
  };

  const renderEquilivalentFoods = () => {
    return (
      <List>
        {equilivalentFoods.map((food) => (
          <ListItem>
            <span>{food.name}</span>
            <span>{food.grams}</span>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderFoodOptions = () => {
    return foodNames.map((foodName) => {
      return <SelectBoxItem text={foodName} value={foodName} />;
    });
  };

  return (
    <div className="App">
      <Card maxWidth="max-w-md" marginTop="mt-4">
        <img src={logo} alt="Lucia Gutkoski Nutricionista Logo" />

        <Title color="orange" marginTop="mt-4">
          alimento:
        </Title>
        <SelectBox
          handleSelect={handleSelect}
          placeholder="pesquise e selecione o alimento"
        >
          {renderFoodOptions()}
        </SelectBox>

        <Title color="orange" marginTop="mt-4">
          gramas:
        </Title>
        <Input
          type="number"
          placeholder="digite uma gramagem"
          value={valueInGrams}
          onChange={handleValueInGramsChange}
        />

        <Flex>
          <Title color="orange" marginTop="mt-4">
            grupo:
          </Title>
          <Title marginTop="mt-4">{group}</Title>
        </Flex>

        <Title color="orange" marginTop="mt-4">
          opções:
        </Title>
        <Input
          type="text"
          placeholder="pesquisar opções"
          value={search}
          onChange={handleSearch}
        />
        <div>{renderEquilivalentFoods()}</div>
      </Card>
    </div>
  );
}

export default App;
